/* [synquery-org-website] PageNavi.scss */
$norm-color: rgb(51, 51, 51);
$norm-bg: rgb(255, 255, 255);
$norm-fill: rgb(178, 178, 178);
$norm-border: rgb(127, 127, 127);
$norm-hover-color: rgb(28, 28, 225);
$dark-color: rgb(242, 242, 242);
$dark-bg: rgb(34, 34, 34);
$dark-fill: rgb(178, 178, 178);
$dark-border: rgb(178, 178, 178);
$dark-hover-color: rgb(255, 112, 210);
#PageNavi {
  position: sticky;
  display: block;
  top: 5.25rem;
  padding: 1rem 0px 1rem 1rem;
  min-width: 12rem;
  height: calc(100vh - 80px);
  overflow-y: hidden;
  transition: transform 0.2s ease 0s;
  >.container {
    display: grid;
    grid-template-rows: 30px 1fr;
    grid-template-columns: 100%;
    height: 100%; overflow-y: hidden;
    padding: 0;
    >.area {
      &.navi-Title {
        grid-row: 1; grid-column: 1;
      }
      &.navi-List {
        grid-row: 2; grid-column: 1;
        overflow-y: auto;
        margin-left: 0;
        margin-top: 0;
        padding: 0;
        >.navi-Goto {
          cursor: pointer;
          &.navi-Focus {
            cursor: default;
            font-weight: bold;
            color: $norm-hover-color;
          }
        }
        h2 {
          font-weight: bold;
          margin-top: 18px;
          margin-bottom: 0;
          font-size: 12px; line-height: 18px;
        }
        h3 {
          margin-top: 18px;
          margin-bottom: 8px;
          padding-left: 18px;
          font-size: 12px;
          &.navi-Focus:before {
            display: inline-block;
            content: "●";
            margin-left: -6px;
            margin-right: -6px;
          }
        }
      }
    }
  }
}
@media all and (max-width:1024px) {
#PageNavi {
  display: none;
}    
}
body.theme-Dark #PageNavi {
  >.container {
    >.area {
      &.navi-List {
        >.navi-Goto {
          &.navi-Focus {
            color: $dark-hover-color;
          }
        }
      }
    }
  }
}
